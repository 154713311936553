import {Link} from "gatsby";
import React, {Component} from "react";
import {foldedListItem, unfoldedListItem} from "./datelist.module.css"

export function perYearPerMonth(posts) {
	return posts
		.reduce((years, item) => {
			const year = years[item.year] || {
				year: item.year,
				months: {},
			}
			const month = year.months[item.month] || {
				ordinal: item.month,
				name: item.monthName,
				items: [],
			}
			return {
				...years,
				[item.year]: {
					...year,
					months: {
						...year.months,
						[item.month]: {
							...month,
							items: [
								...month.items,
								item
							]
						}
					}
				}
			}
		}, [])
}

class CollapseableListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hidden: true
		}
	}

	handleClick(e) {
		e.stopPropagation();
		this.setState(state => ({
			...state,
			hidden: !state.hidden
		}))
	}

	render() {
		const {children, key, text} = this.props
		const {hidden} = this.state
		return <li key={key} onClick={e => this.handleClick(e)} className={hidden ? foldedListItem : unfoldedListItem}>
			{text}
			{hidden ? "" : children}
		</li>
	}
}

const EverpostLink = ({post}) => <Link to={`/everblog/${post.id}`} title={post.title}>{post.title}</Link>

const ToCPost = ({post}) => <li key={post.id}>
	<EverpostLink post={post}/>
</li>

export const EverPostDateList = ({years}) => <ul>
	{years.map(year => <CollapseableListItem key={year.year} text={year.year}>
		<ul>
			{year.months.map(month => <CollapseableListItem key={month.ordinal} text={month.name}>
				<ul>
					{month.items.map(post => <ToCPost post={post}/>)}
				</ul>
			</CollapseableListItem>)}
		</ul>
	</CollapseableListItem>)}
</ul>

function sortYearsAndMonths(years) {
	return years.sort(byKey('year')).map(year => {
		return ({
			...year,
			months: year.months.sort(byKey('ordinal')).map(month => ({
				...month,
				items: month.items.sort(byKey('createdTimestamp'))
			}))
		})
	})

	function byKey(key) {
		return (a, b) => b[key] - a[key];
	}
}

function makeSimple(years) {
	return Object.values(years).map(year => ({
		...year,
		months: Object.values(year.months),
	}))
}

export function createYears(posts) {
	const yearsNotSorted = makeSimple(perYearPerMonth(posts));
	const years = sortYearsAndMonths(yearsNotSorted)
	return years;
}
