import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import {createYears, EverPostDateList} from "../components/datelist";

const Title = ({title}) => <h1 className="section-headline">{title}</h1>

class EverblogArchive extends React.Component {
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const page = {
			title: `Archive`
		}

		const posts = get(this, 'props.data.allContentfulEverpost').edges.map(({node}) => ({
			title: node.title,
			id: node.id,
			year: node.year,
			month: node.month,
			monthName: node.monthName,
			createdTimestamp: node.createdTimestamp,
		}));
		const title = `${page.title} | ${siteTitle}`;
		return (
			<Layout location={this.props.location} title={title}>
				<div className="mainArticle">
					<div className="wrapper">
						<Title title={page.title}/>
						<EverPostDateList years={createYears(posts)}/>
					</div>
				</div>
			</Layout>
		)
	}
}

export default EverblogArchive

export const pageQuery = graphql`
query ArchiveQuery {
  allContentfulEverpost(sort: {order: DESC, fields: [created]}, filter: {tags: {elemMatch: {name: {eq: "published", ne: "page"}}}}) {
    edges {
      node {
        title
        id: contentful_id
        createdTimestamp: created(formatString: "X")
        month: created(formatString: "MM")
        monthName: created(formatString: "MMMM")
        year: created(formatString: "YYYY")
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`
